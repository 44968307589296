import { config } from "./config";
export var widget = {
    config: {
        width: "100%",
        height: "460px",
        scrclickid: null,
        plcmt: null,
    },
    callback: function () { },
    init: function (widgetConfig) {
        this.attachIframe(Object.assign(this.config, widgetConfig));
        this.setEventListeners();
        return this;
    },
    getIframe: function () {
        var searchParams = new URLSearchParams();
        searchParams.append('widget_id', this.config.widget_id);
        searchParams.append('page_url', location.host + location.pathname);
        searchParams.append('domain', location.host);
        searchParams.append('cc_page_url', 'conversion-cowboys.com' + location.pathname);
        // pass preview params to the widget
        if (this.config.preview) {
            searchParams.append('preview_mode', 'true');
            for (var key in this.config.preview) {
                searchParams.append(key, this.config.preview[key]);
            }
        }
        if (this.config["scrclickid"]) {
            searchParams.append('scrclickid', this.config["scrclickid"]);
        }
        if (this.config["plcmt"]) {
            searchParams.append('plcmt', this.config["plcmt"]);
        }
        var src = config.__APP_URL__ + "/widget.html?" + searchParams.toString();
        return "<iframe src='" + src + "' id='" + this.config.widget_id + "' width='" + this.config.width + "' height='" + this.config.height + "' scrolling='no' frameBorder='0' ></iframe>";
    },
    setEventListeners: function () {
        var eventMethod = window.addEventListener
            ? "addEventListener"
            : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
        eventer(messageEvent, this.handleIframeEvents, false);
    },
    handleIframeEvents: function (event) {
        var config = window["COWBOY_WIDGET"]["config"];
        if (event["data"]["name"] === "height") {
            document.getElementById(config["widget_id"]).style.height =
                event["data"]["value"];
            document
                .getElementById(config["widget_id"])
                .setAttribute("height", event["data"]["value"]);
            return;
        }
    },
    attachIframe: function (config) {
        this.config = config;
        var sidebar = null;
        if (this.config.selector_type === "class") {
            sidebar = document.getElementsByClassName(this.config.selector)[0];
        }
        if (this.config.selector_type === "id") {
            sidebar = document.getElementById(this.config.selector);
        }
        if (this.config.selector_type === "query_selector") {
            sidebar = document.querySelector(this.config.selector);
        }
        if (sidebar) {
            sidebar.insertAdjacentHTML("afterbegin", this.getIframe());
        }
    },
    addStyle: function (styleString) {
        var style = document.createElement("style");
        style.textContent = styleString;
        document.head.append(style);
    },
    log: function (msg) {
        console.log("COWBOY WIDGET: " + msg);
    },
};
if (window["COWBOY_WIDGET"]) {
    window["COWBOY_WIDGET"] = widget.init(window["COWBOY_WIDGET"]);
}
else {
    widget.log("CONFIG MISSING");
}
